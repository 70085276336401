import React, { useState } from "react";
import Drawer from "Clutch/Molecules/Drawer";
import { useProductPageStore } from '../../../contexts/ProductPageStore';
import Panel from "Atoms/Panel";
import Typography from "Atoms/Typography";
import { useVehicleStore } from "Clutch/Stores/VehicleStore";
import Button from "Molecules/Button";
import styles from "./additionalFacetDrawer.module.scss";
import FacetSection from "./facetSection/facetSection";
import { useEffect } from "react";
import CoveringSpinner from "Atoms/CoveringSpinner/CoveringSpinner";
import { createCheckFitmentRequest } from "../../../utils/fitment/checkFitmentUtils";

const engineLevelFacetOptions = {
  engineDisplacement: "GA_EngineDisplacement",
  engineVersion: "GA_EngineVersion",
  engineQualifier: "GA_EngineQualifier",
  engineCode: "GA_EngineCode",
  engineCamshaftType: "GA_EngineCamshaftType",
  engineFuelSystem: "GA_EngineFuelSystem",
};

const isFacetOptionHeirarchial = (facetType) => {
  switch (facetType) {
    case engineLevelFacetOptions.engineDisplacement:
    case engineLevelFacetOptions.engineVersion:
    case engineLevelFacetOptions.engineQualifier:
      return true;
    default:
      return false;
  }
};

const AdditionalFacetDrawer = () => {
  const [selectedFacetIds, setSelectedFacetIds] = useState({});
  const [facetLevels, setFacetLevels] = useState([]);
  const [isVerifyFitmentButtonDisabled, setIsVerifyFitmentButtonDisabled] =
    useState(true);
  const [isVerifyingFitment, setIsVerifyingFitment] = useState(false);
  const isAdditionalFacetDrawerOpen = useProductPageStore(x => x.isAdditionalFacetDrawerOpen);
  const hideAdditionalFacetDrawer = useProductPageStore(x => x.hideAdditionalFacetDrawer);
  const checkFitment = useProductPageStore(x => x.checkFitment);
  const fitment = useProductPageStore(x => x.fitment);
  const selectedCustomerProjectState = useVehicleStore(
    (x) => x.context.selectedCustomerProject
  );
  const updateCustomerProjectData = useVehicleStore((x) => x.updateCustomerProjectData)
  const customerProjects = useVehicleStore((x) => x.context.customerProjects);
  const selectedFitment = fitment?.find(
    (fitment) => fitment.key === selectedCustomerProjectState.projectId
  );

  const handleSelectedFacetIdChange = (facetType, facetId) => {
    let newSelectedFacetIds;
    if (isFacetOptionHeirarchial(facetType)) {
      const existingDisplacement =
        selectedFacetIds[engineLevelFacetOptions.engineDisplacement];
      const existingVersion =
        selectedFacetIds[engineLevelFacetOptions.engineVersion];

      if (facetType === engineLevelFacetOptions.engineDisplacement) {
        newSelectedFacetIds = { [facetType]: parseInt(facetId) };
      } else if (facetType === engineLevelFacetOptions.engineVersion) {
        newSelectedFacetIds = { [facetType]: parseInt(facetId) };
        if (existingDisplacement)
          newSelectedFacetIds[engineLevelFacetOptions.engineDisplacement] =
            existingDisplacement;
      } else if (facetType === engineLevelFacetOptions.engineQualifier) {
        newSelectedFacetIds = { [facetType]: parseInt(facetId) };
        if (existingDisplacement)
          newSelectedFacetIds[engineLevelFacetOptions.engineDisplacement] =
            existingDisplacement;
        if (existingVersion)
          newSelectedFacetIds[engineLevelFacetOptions.engineVersion] =
            existingVersion;
      }
    } else {
      newSelectedFacetIds = {
        ...selectedFacetIds,
        [facetType]: parseInt(facetId),
      };
    }

    setSelectedFacetIds(newSelectedFacetIds);
  };

  const removeFacetLevels = () => {
    const numSelectedFacetIds = Object.keys(selectedFacetIds).length;

    let newFacetLevels = [];
    for (let i = 0; i < numSelectedFacetIds; i++)
      newFacetLevels.push(facetLevels[i]);

    setFacetLevels(newFacetLevels);
  };

  const buildFacetLevels = (additionalEngineLevelFacets) => {
    const facetType = additionalEngineLevelFacets.type;
    let newFacetLevels = [];
    const existingDisplacement = facetLevels.find(
      (facetLevel) =>
        facetLevel.type === engineLevelFacetOptions.engineDisplacement
    );
    const existingVersion = facetLevels.find(
      (facetLevel) => facetLevel.type === engineLevelFacetOptions.engineVersion
    );

    if (facetType === engineLevelFacetOptions.engineDisplacement)
      newFacetLevels.push(additionalEngineLevelFacets);
    else if (facetType === engineLevelFacetOptions.engineVersion) {
      if (existingDisplacement) newFacetLevels.push(existingDisplacement);
      newFacetLevels.push(additionalEngineLevelFacets);
    } else if (facetType === engineLevelFacetOptions.engineQualifier) {
      if (existingDisplacement) newFacetLevels.push(existingDisplacement);
      if (existingVersion) newFacetLevels.push(existingVersion);
      newFacetLevels.push(additionalEngineLevelFacets);
    } else {
      newFacetLevels = [...facetLevels, additionalEngineLevelFacets];
    }

    return newFacetLevels;
  };

  useEffect(() => {
    const selectedFacetIdsLength = Object.keys(selectedFacetIds).length;
    if (selectedFacetIdsLength > 0) verifyFitment();
  }, [JSON.stringify(selectedFacetIds)]);

  useEffect(() => {
    if (selectedFitment?.value) {
      const needsMoreInfo = selectedFitment.value.needsMoreInfo;
      const selectedFacetIdsLength = Object.keys(selectedFacetIds).length;

      if (needsMoreInfo === false) {
        if (selectedFacetIdsLength === facetLevels.length)
          setIsVerifyFitmentButtonDisabled(false);
      } else setIsVerifyFitmentButtonDisabled(true);
    }
  }, [
    selectedFitment,
    JSON.stringify(facetLevels),
    JSON.stringify(selectedFacetIds),
  ]);

  useEffect(() => {
    if (selectedFitment?.value) {
      const needsMoreInfo = selectedFitment.value.needsMoreInfo;
      if (needsMoreInfo === true) {
        const additionalEngineLevelFacets =
          selectedFitment?.value.additionalEngineLevelFacets;
        if (additionalEngineLevelFacets) {
          const newFacetLevels = buildFacetLevels(additionalEngineLevelFacets);
          setFacetLevels(newFacetLevels);
        }
      } else {
        removeFacetLevels();
      }
    }
    setIsVerifyingFitment(false);
  }, [selectedFitment]);

  const hasFacetLevelOfType = (facetType) => {
    return facetLevels.find((facetLevel) => facetLevel.type === facetType);
  };

  const verifyFitment = async () => {
    setIsVerifyingFitment(true);
    const selectedCustomerProjectId = selectedCustomerProjectState.projectId;
    const selectedCustomerProject = customerProjects?.find(
      (customerVehicle) =>
        customerVehicle.projectId === selectedCustomerProjectId
    );

    if (selectedFacetIds[engineLevelFacetOptions.engineDisplacement]) {
      selectedCustomerProject.engineDisplacementId =
        selectedFacetIds[engineLevelFacetOptions.engineDisplacement];
    } else if (hasFacetLevelOfType(engineLevelFacetOptions.engineDisplacement))
      selectedCustomerProject.engineDisplacementId = null;

    if (selectedFacetIds[engineLevelFacetOptions.engineVersion]) {
      selectedCustomerProject.engineVersionId =
        selectedFacetIds[engineLevelFacetOptions.engineVersion];
    } else if (hasFacetLevelOfType(engineLevelFacetOptions.engineVersion))
      selectedCustomerProject.engineVersionId = null;

    if (selectedFacetIds[engineLevelFacetOptions.engineQualifier]) {
      selectedCustomerProject.engineQualifierId =
        selectedFacetIds[engineLevelFacetOptions.engineQualifier];
    } else if (hasFacetLevelOfType(engineLevelFacetOptions.engineQualifier)) {
      selectedCustomerProject.engineQualifierId = null;
    }

    if (selectedFacetIds[engineLevelFacetOptions.engineCamshaftType]) {
      selectedCustomerProject.engineCamshaftTypeId =
        selectedFacetIds[engineLevelFacetOptions.engineCamshaftType];
    } else if (
      hasFacetLevelOfType(engineLevelFacetOptions.engineCamshaftType)
    ) {
      selectedCustomerProject.engineCamshaftTypeId = null;
    }

    if (selectedFacetIds[engineLevelFacetOptions.engineCode]) {
      selectedCustomerProject.engineCodeId =
        selectedFacetIds[engineLevelFacetOptions.engineCode];
    } else if (hasFacetLevelOfType(engineLevelFacetOptions.engineCode)) {
      selectedCustomerProject.engineCodeId = null;
    }

    if (selectedFacetIds[engineLevelFacetOptions.engineFuelSystem]) {
      selectedCustomerProject.engineFuelSystemId =
        selectedFacetIds[engineLevelFacetOptions.engineFuelSystem];
    } else if (hasFacetLevelOfType(engineLevelFacetOptions.engineFuelSystem)) {
      selectedCustomerProject.engineFuelSystemId = null;
    }

    const checkFitmentRequest = createCheckFitmentRequest(customerProjects);

    checkFitment(checkFitmentRequest);

    await updateCustomerProjectData({
      year: selectedCustomerProject.year,
      makeId: selectedCustomerProject.makeId,
      make: selectedCustomerProject.make,
      modelId: selectedCustomerProject.modelId,
      model: selectedCustomerProject.model,
      raceTypeId: selectedCustomerProject.raceTypeId,
      raceTypeDisplayName: selectedCustomerProject.raceTypeDisplayName,
      vehicleBaseId: selectedCustomerProject.vehicleBaseId,
      engineIds: {
        engineManufacturerId: selectedCustomerProject.engineManufacturerId,
        engineMakeId: selectedCustomerProject.engineMakeId,
        engineSeriesId: selectedCustomerProject.engineSeriesId,
        engineConfigurationId: selectedCustomerProject.engineConfigurationId,
        engineVersionId: selectedCustomerProject.engineVersionId,
        engineQualifierId: selectedCustomerProject.engineQualifierId,
        engineDisplacementId: selectedCustomerProject.engineDisplacementId,
        engineCodeId: selectedCustomerProject.engineCodeId,
        engineFuelSystemId: selectedCustomerProject.engineFuelSystemId,
        engineCamshaftTypeId: selectedCustomerProject.engineCamshaftTypeId
      },
      customerProjectId: selectedCustomerProjectId
  });

  };

  const handleVerifyFitmentClick = () => {
    hideAdditionalFacetDrawer();
  };

  return (
    <Drawer
      location={"right"}
      width={640}
      open={!!isAdditionalFacetDrawerOpen}
      closeFunction={() => hideAdditionalFacetDrawer()}
    >
      <Panel className={styles.drawer} layer={1}>
        <CoveringSpinner isLoading={isVerifyingFitment}>
          <div className={styles.coveringSpinnerContent}>
            <Typography className={styles.title} font={"bold"} size={1.25}>
              Additional information needed to verify fitment
            </Typography>
            <Typography className={styles.correctOptionText} font={"bold"} size={1}>
              Please select the correct option
            </Typography>
            <Panel className={styles.drawer_panel} layer={0}>
              {facetLevels.map((facetLevel) => {
                const facetType = facetLevel.type;
                return (
                  <FacetSection
                    facetInfo={facetLevel}
                    selectedFacetId={selectedFacetIds[facetType]}
                    setSelectedFacetId={(id) =>
                      handleSelectedFacetIdChange(facetType, id)
                    }
                  />
                );
              })}
              <div className={styles.buttonContainer}>
                <Button
                  intent={"positive"}
                  disabled={isVerifyFitmentButtonDisabled}
                  text={"Verify Fitment"}
                  onClick={handleVerifyFitmentClick}
                  size={"small"}
                  segmentEvent={{
                    event: "Test",
                    properties: {
                      element: "TestElement",
                    },
                  }}
                />
              </div>
            </Panel>
          </div>
        </CoveringSpinner>
      </Panel>
    </Drawer>
  );
};

export default AdditionalFacetDrawer;
